.navbar_container {
  display: flex;
  align-items: center;
  color: white;
  height: 110px;
  padding-left: 70px;
}

.no_bg {
  background-color: transparent;
}

.with_bg {
  background-color: black;
}

.navbar_logo {
  flex-shrink: 0;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  order: 2;
}

.logo_image {
  width: 150px;
  height: auto;
  margin-left: -140%;
  display: block;
}

.navbar_list {
  display: flex;
  order: 1;
}

.navbar_list ul {
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 0;
  margin: 0;
}

.navbar_text {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  font-size: 20px;
}

/* Hamburger menu icon */
.menu_toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
  order: 3;
}

.menu_icon {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: all 0.3s ease;
}

.menu_icon.open:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu_icon.open:nth-child(2) {
  opacity: 0;
}

.menu_icon.open:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Sidebar styles */
.sidebar {
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  height: 100%;
  background-color: black;
  transition: left 0.3s ease;
  display: flex;
}

.sidebar.open {
  left: 0;
}

.sidebar ul {
  list-style: none;
  padding-left: 50px;
  padding-top: 80px;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sidebar_text {
  text-decoration: none;
  color: white;
  font-size: 16px;
}

.close_toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.close_icon {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: all 0.3s ease;
  transform-origin: center;
}

.close_icon:nth-child(1) {
  transform: rotate(45deg);
}

.close_icon:nth-child(2) {
  margin-top: -7px;
  transform: rotate(-45deg);
}

/* Tablet View */
@media (max-width: 1200px) {
  .navbar_container {
    height: auto;
    padding: 10px 30px;
  }

  .navbar_logo {
    order: 2;
  }

  .logo_image {
    width: 120px;
    height: auto;
    margin-left: -10%;
  }

  .navbar_list {
    display: none;
    order: 1;
  }

  .navbar_list.open {
    display: flex;
  }

  .menu_toggle {
    display: flex;
    order: 2;
  }

  .navbar_text {
    display: none;
  }
}

/* Landscape Tablet View */
@media only screen and (max-width: 768px) {
  .sidebar_text {
    font-size: 14px;
  }
  .sidebar ul {
    padding-left: 50px;
    padding-top: 60px;
    gap: 20px;
  }
  .logo_image {
    width: 100px;
    height: auto;
    margin-left: -15%;
  }
  .sidebar {
    left: -230px;
    width: 230px;
  }
  .close_icon {
    width: 20px;
  }
  .menu_icon {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 3px 0;
    transition: all 0.3s ease;
  }
}

/* Mobile View */
@media only screen and (max-width: 576px) {
  .sidebar_text {
    font-size: 12px;
  }
  .sidebar {
    left: -200px;
    width: 200px;
  }
  .sidebar ul {
    padding-left: 40px;
    padding-top: 60px;
    gap: 15px;
  }
  .logo_image {
    width: 90px;
    height: auto;
    margin-left: -10%;
  }
  .close_icon {
    width: 18px;
    height: 2px;
  }
  .close_icon:nth-child(2) {
    margin-top: -6px;
    transform: rotate(-45deg);
  }
  .menu_icon {
    width: 22px;
    height: 2px;
    background-color: white;
    margin: 3px 0;
    transition: all 0.3s ease;
  }
}

/* Landscape Mobile View */
@media (max-width: 480px) {
  .sidebar_text {
    font-size: 10px;
  }
  .sidebar {
    left: -180px;
    width: 180px;
  }
  .sidebar ul {
    padding-left: 40px;
    padding-top: 45px;
    gap: 10px;
  }
  .logo_image {
    width: 80px;
    height: auto;
    margin-left: -10%;
  }
}

/* Fold Mobile View */
@media (max-width: 280px) {
  .sidebar_text {
    font-size: 10px;
  }
  .sidebar {
    left: -180px;
    width: 180px;
  }
  .sidebar ul {
    padding-left: 40px;
    padding-top: 45px;
    gap: 10px;
  }
  .logo_image {
    width: 80px;
    height: auto;
    margin-left: -10%;
  }
}

