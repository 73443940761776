.catalogue_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.catalogue_list_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.catalogue_item {
    margin-bottom: 20px;
    text-align: center;
}

.catalogue_title {
    margin-bottom: 50px;
    font-size: 28px;
}

.pdf_thumbnail {
    cursor: pointer;
    margin: 20px;
    display: flex;
    justify-content: center;
}

/* Landscape Tablet View */
@media (max-width: 768px) {
.catalogue_title {
    margin-bottom: 20px;
    font-size: 22px;
}
}

/* Mobile View */
@media (max-width: 480px) {
    .catalogue_title {
        margin-bottom: 14px;
        font-size: 14px;
    }
}