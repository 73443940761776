.footer_container {
    background-color: black;
}

.footer_first_container {
    padding-block: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-bottom: 2px solid;
    border-color: #0F0F0F;
}

.footer_ilvasto_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer_ilvasto_logo {
    margin-bottom: 20px;
}

.social_media {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.logo_image_footer {
    height: 80px;
    width: auto;
    margin-right: 20px;
}

.logo_image_sosmed {
    height: 25px;
    width: auto;
    margin-right: 20px;
}

.footer_second_row {
    color: white;
    display: flex;
    flex-direction: column;
}

.footer_third_row {
    color: white;
    display: flex;
    flex-direction: column;
}

.footer_forth_row {
    color: white;
    display: flex;
    flex-direction: column;
}

.footer_third_row span {
    margin-bottom: 20px;
}

.footer_second_container {
    padding-block: 20px;
    text-align: center;
}

.footer_second_container span {
    color: white;
    font-size: 10px;
}

/* Tablet View */
@media (max-width: 1200px) {
    .logo_image_footer {
        height: 60px;
        width: auto;
        margin-right: 15px;
    }
    
    .logo_image_sosmed {
        height: 18px;
        width: auto;
        margin-right: 15px;
    }

    .footer_second_row h3 {
        font-size: 18px;
    }

    .footer_third_row h3 {
        font-size: 18px;
    }

    .footer_forth_row h3 {
        font-size: 18px;
    }
}

/* Landscape Tablet View */
@media (max-width: 768px) {
    .footer_first_container {
        padding-block: 50px;
        display: flex;
        flex-direction: column;
        border-bottom: 2px solid;
        border-color: #0F0F0F;
        padding-left: 50px;
    }
    .social_media {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: start;
    }
    .footer_ilvasto_container {
        display: flex;
        flex-direction: column;
        align-items: start;
    }
    .footer_second_row {
        margin-top: 20px;
    }
    .footer_third_row {
        margin-top: 20px;
    }
}

/* Mobile View */
@media (max-width: 576px) {
    .logo_image_footer {
        height: 50px;
        width: auto;
        margin-right: 15px;
    }
    
    .logo_image_sosmed {
        height: 14px;
        width: auto;
        margin-right: 15px;
    }

    .footer_second_row h3 {
        font-size: 16px;
    }

    .footer_second_row span {
        font-size: 14px;
    }

    .footer_third_row h3 {
        font-size: 16px;
    }

    .footer_third_row span {
        font-size: 14px;
    }

    .footer_forth_row h3 {
        font-size: 16px;
    }
}