.home_container {
    margin-top: -110px;
}

.video_banner {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: relative;
}

.video_banner_detail_container {
    margin-top: -350px;
    padding-left: 90px;
    position: relative;
}

.title_video_banner {
    color: #FFF;
    font-size: 48px;
    margin-bottom: 0;
}

.short_desc_video_banner {
    color: #FFF;
    font-size: 30px;
}

.app_link_buttons {
    margin-top: 70px;
}

.app_link_button {
    margin-right: 30px;
}

.app_link_image {
    width: 150px;
}

.section2_container {
    margin-top: 10%;
    margin-bottom: 5%;
    text-align: center;
}

.section2_title {
    font-style: italic;
    font-size: 32px;
}

.section2_description {
    font-size: 36px;
    font-style: italic;
    width: 60%;
    margin: 0 auto;
    margin-top: 20px;
    text-align: center;
}

.section3_container {
    background-color: rgba(239, 235, 234, 1);
    padding: 5% 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.section3_image_container {
    width: 50%;
    padding-right: 75px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.section3_detail_container {
    width: 50%;
    padding-left: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section3_title {
    font-size: 56px;
    font-weight: 500;
    width: 55%;
}

.section3_description {
    font-size: 28px;
    width: 65%;
}

.section4_container {
    padding: 5% 0;
    display: flex;
    justify-content: center;
    gap: 10%;
}

.section4_container > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.section4_image {
    width: 200px;
    height: auto;
}

.section4_title {
    margin-top: 10px;
    font-size: 24px;
}

.section5_container {
    padding-bottom: 5%;
    padding-top: 0%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.section5_image_container {
    width: 50%;
    padding-right: 75px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.section5_detail_container {
    width: 50%;
    padding-left: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section5_title {
    font-size: 56px;
    font-weight: 500;
    width: 55%;
}

.section5_description {
    font-size: 28px;
    width: 65%;
}

.section6_container {
    background-color: rgba(239, 235, 234, 1);
    padding: 5% 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.section6_image_container {
    width: 50%;
    padding-right: 75px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.section6_detail_container {
    width: 50%;
    padding-left: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.section6_title {
    font-size: 56px;
    font-weight: 500;
    width: 60%;
}

.section6_description {
    font-size: 28px;
    width: 60%;
}

.instagram_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-block: 50px;
    margin-inline: 10px;
}

.instagram_text {
    display: flex;
    justify-content: center;
    align-items: center;
}

.instagram_text2 {
    margin-top: 100px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.instagram_container span {
    font-style: italic;
    font-weight: 500;
    font-size: 42px;
    color: #333;
    text-align: center;
}

.image_grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.image_grid a {
    margin: 5px;
}

.image_grid img {
    width: 350px;
    height: auto;
}

.stroke_button {
    margin-top: 50px;
    border: 3px solid black;
    background-color: transparent;
    padding: 15px 40px;
    font-size: 16px;
    cursor: pointer;
}

/* Tablet View */
@media (max-width: 1200px) {
    .video_banner {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .video_banner_detail_container {
        margin-top: -250px;
        padding-left: 30px;
        position: relative;
    }
    
    .title_video_banner {
        color: #FFF;
        font-size: 38px;
        margin-bottom: 0;
    }
    
    .short_desc_video_banner {
        color: #FFF;
        font-size: 20px;
    }
    
    .app_link_buttons {
        margin-top: 30px;
    }
    
    .app_link_button {
        margin-right: 30px;
    }
    
    .app_link_image {
        width: 110px;
    }
    
    .section2_title {
        font-style: italic;
        font-size: 28px;
    }
    
    .section2_description {
        font-size: 30px;
        font-style: italic;
        width: 70%;
        margin: 0 auto;
        margin-top: 20px;
        text-align: center;
    }

    .section3_container {
        flex-direction: row;
        justify-content: space-evenly;
    }
    
    .section3_image_container {
        width: 100%;
        padding-right: 0px;
        justify-content: center;
    }

    .section3_image {
        width: 90%;
        height: auto;
    }
    
    .section3_detail_container {
        margin-top: 20px;
        width: 70%;
        padding-inline: 20px;
    }
    
    .section3_title {
        font-size: 32px;
        font-weight: 500;
        width: 100%;
    }
    
    .section3_description {
        font-size: 22px;
        width: 100%;
    }

    .section4_container {
        padding: 5% 0;
        display: flex;
        justify-content: center;
        gap: 20px;
    }
    
    .section4_container > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 30%;
    }
    
    .section4_image {
        width: 170px;
        height: auto;
    }
    
    .section4_title {
        margin-top: 10px;
        font-size: 24px;
    }

    .section5_container {
        flex-direction: row;
        justify-content: space-evenly;
    }
    
    .section5_image_container {
        width: 100%;
        padding-right: 0px;
        justify-content: center;
    }

    .section5_image {
        width: 90%;
        height: auto;
    }
    
    .section5_detail_container {
        margin-top: 20px;
        width: 70%;
        padding-inline: 20px;
    }
    
    .section5_title {
        font-size: 32px;
        font-weight: 500;
        width: 100%;
    }
    
    .section5_description {
        font-size: 22px;
        width: 100%;
    }

    .section6_container {
        flex-direction: row;
        justify-content: space-evenly;
    }
    
    .section6_image_container {
        width: 100%;
        padding-right: 0px;
        justify-content: center;
    }

    .section6_image {
        width: 90%;
        height: auto;
    }
    
    .section6_detail_container {
        margin-top: 20px;
        width: 70%;
        padding-inline: 20px;
    }
    
    .section6_title {
        font-size: 32px;
        font-weight: 500;
        width: 100%;
    }
    
    .section6_description {
        font-size: 22px;
        width: 100%;
    }

    .instagram_container span {
        font-size: 28px;
    }

    .instagram_text2 {
        margin-top: 70px;
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
}

/* Landscape Tablet View */
@media (max-width: 768px) {
    .video_banner_detail_container {
        margin-top: -180px;
        padding-left: 30px;
        position: relative;
    }
    
    .title_video_banner {
        color: #FFF;
        font-size: 28px;
        margin-bottom: 0;
    }
    
    .short_desc_video_banner {
        color: #FFF;
        font-size: 18px;
    }
    
    .app_link_buttons {
        margin-top: 30px;
    }
    
    .app_link_button {
        margin-right: 10px;
    }
    
    .app_link_image {
        width: 70px;
    }

    .section2_title {
        font-style: italic;
        font-size: 24px;
    }
    
    .section2_description {
        font-size: 26px;
        font-style: italic;
        width: 80%;
        margin: 0 auto;
        margin-top: 20px;
        text-align: center;
    }

    .section3_container {
        flex-direction: row;
        justify-content: space-evenly;
    }
    
    .section3_image_container {
        width: 100%;
        padding-right: 0px;
        justify-content: center;
    }

    .section3_image {
        width: 80%;
        height: auto;
    }
    
    .section3_detail_container {
        margin-top: 20px;
        width: 70%;
        padding-inline: 20px;
    }
    
    .section3_title {
        font-size: 24px;
        font-weight: 500;
        width: 100%;
    }
    
    .section3_description {
        font-size: 16px;
        width: 100%;
    }

    .section4_container {
        padding: 5% 0;
        display: flex;
        justify-content: center;
        gap: 20px;
    }
    
    .section4_container > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 30%;
    }
    
    .section4_image {
        width: 130px;
        height: auto;
    }
    
    .section4_title {
        margin-top: 10px;
        font-size: 18px;
    }

    .section5_container {
        flex-direction: row;
        justify-content: space-evenly;
    }
    
    .section5_image_container {
        width: 100%;
        padding-right: 0px;
        justify-content: center;
    }

    .section5_image {
        width: 80%;
        height: auto;
    }
    
    .section5_detail_container {
        margin-top: 20px;
        width: 70%;
        padding-inline: 20px;
    }
    
    .section5_title {
        font-size: 24px;
        font-weight: 500;
        width: 100%;
    }
    
    .section5_description {
        font-size: 16px;
        width: 100%;
    }

    .section6_container {
        flex-direction: row;
        justify-content: space-evenly;
    }
    
    .section6_image_container {
        width: 100%;
        padding-right: 0px;
        justify-content: center;
    }

    .section6_image {
        width: 80%;
        height: auto;
    }
    
    .section6_detail_container {
        margin-top: 20px;
        width: 70%;
        padding-inline: 20px;
    }
    
    .section6_title {
        font-size: 24px;
        font-weight: 500;
        width: 100%;
    }
    
    .section6_description {
        font-size: 16px;
        width: 100%;
    }

    .instagram_container span {
        font-size: 22px;
    }

    .instagram_text2 {
        margin-top: 50px;
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

/* Mobile View */
@media (max-width: 576px) {
    .video_banner_detail_container {
        margin-top: -130px;
        padding-left: 30px;
        position: relative;
    }
    
    .title_video_banner {
        color: #FFF;
        font-size: 16px;
        margin-bottom: 0;
    }
    
    .short_desc_video_banner {
        color: #FFF;
        font-size: 14px;
    }
    
    .app_link_buttons {
        margin-top: 20px;
    }
    
    .app_link_button {
        margin-right: 10px;
    }
    
    .app_link_image {
        width: 60px;
    }

    .section2_title {
        font-style: italic;
        font-size: 20px;
    }
    
    .section2_description {
        font-size: 22px;
        font-style: italic;
        width: 85%;
        margin: 0 auto;
        margin-top: 20px;
        text-align: center;
    }

    .section3_container {
        flex-direction: column;
    }
    
    .section3_image_container {
        width: 100%;
        padding-right: 0px;
        justify-content: center;
    }

    .section3_image {
        width: 70%;
        height: auto;
    }
    
    .section3_detail_container {
        text-align: center;
        margin-top: 20px;
        width: 80%;
        padding-inline: 20px;
    }
    
    .section3_title {
        font-size: 22px;
        font-weight: 500;
        width: 100%;
    }
    
    .section3_description {
        font-size: 14px;
        width: 100%;
    }

    .section4_container {
        padding: 5% 0;
        display: flex;
        justify-content: center;
        gap: 0px;
    }
    
    .section4_container > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 30%;
    }
    
    .section4_image {
        width: 100px;
        height: auto;
    }
    
    .section4_title {
        width: 80%;
        margin-top: 10px;
        font-size: 14px;
    } 

    .section5_container {
        flex-direction: column;
    }
    
    .section5_image_container {
        width: 100%;
        padding-right: 0px;
        justify-content: center;
    }

    .section5_image {
        width: 70%;
        height: auto;
    }
    
    .section5_detail_container {
        text-align: center;
        margin-top: 20px;
        width: 80%;
        padding-inline: 20px;
    }
    
    .section5_title {
        font-size: 22px;
        font-weight: 500;
        width: 100%;
    }
    
    .section5_description {
        font-size: 14px;
        width: 100%;
    }
    
    .section6_container {
        flex-direction: column-reverse;
    }
    
    .section6_image_container {
        width: 100%;
        padding-right: 0px;
        justify-content: center;
    }

    .section6_image {
        width: 70%;
        height: auto;
    }
    
    .section6_detail_container {
        text-align: center;
        margin-top: 20px;
        width: 80%;
        padding-inline: 20px;
    }
    
    .section6_title {
        font-size: 22px;
        font-weight: 500;
        width: 100%;
    }
    
    .section6_description {
        font-size: 14px;
        width: 100%;
    }

    .instagram_container span {
        font-size: 18px;
    }

    .instagram_text2 {
        margin-top: 40px;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .image_grid img {
        width: 180px;
        height: auto;
    }
}

/* Landscape Mobile View */
@media (max-width: 480px) {
    .video_banner_detail_container {
        margin-top: -100px;
        padding-left: 30px;
        position: relative;
    }
    
    .title_video_banner {
        color: #FFF;
        font-size: 14px;
        margin-bottom: -5px;
    }
    
    .short_desc_video_banner {
        color: #FFF;
        font-size: 12px;
        margin-top: 0;
    }
    
    .app_link_buttons {
        margin-top: 5px;
    }
    
    .app_link_button {
        margin-right: 5px;
    }
    
    .app_link_image {
        width: 50px;
    }

    .section2_container {
        margin-top: 5%;
        margin-bottom: 5%;
        text-align: center;
    }

    .section2_title {
        font-style: italic;
        font-size: 12px;
    }
    
    .section2_description {
        font-size: 12px;
        font-style: italic;
        width: 85%;
        margin: 0 auto;
        margin-top: 10px;
        text-align: center;
    }

    .section3_container {
        flex-direction: column;
    }
    
    .section3_image_container {
        width: 100%;
        padding-right: 0px;
        justify-content: center;
    }

    .section3_image {
        width: 60%;
        height: auto;
    }
    
    .section3_detail_container {
        text-align: center;
        margin-top: 20px;
        width: 80%;
        padding-inline: 20px;
    }
    
    .section3_title {
        font-size: 16px;
        font-weight: 500;
        width: 100%;
    }
    
    .section3_description {
        font-size: 12px;
        width: 100%;
        margin-top: 5px;
    }

    .section4_image {
        width: 80px;
        height: auto;
    }
    
    .section4_title {
        width: 80%;
        margin-top: 10px;
        font-size: 12px;
    }

    .section5_container {
        flex-direction: column;
    }
    
    .section5_image_container {
        width: 100%;
        padding-right: 0px;
        justify-content: center;
    }

    .section5_image {
        width: 60%;
        height: auto;
    }
    
    .section5_detail_container {
        text-align: center;
        margin-top: 20px;
        width: 80%;
        padding-inline: 20px;
    }
    
    .section5_title {
        font-size: 16px;
        font-weight: 500;
        width: 100%;
    }
    
    .section5_description {
        font-size: 12px;
        width: 100%;
        margin-top: 5px;
    }

    .section6_image_container {
        width: 100%;
        padding-right: 0px;
        justify-content: center;
    }

    .section6_image {
        width: 60%;
        height: auto;
    }
    
    .section6_detail_container {
        text-align: center;
        margin-top: 20px;
        width: 80%;
        padding-inline: 20px;
    }
    
    .section6_title {
        font-size: 16px;
        font-weight: 500;
        width: 100%;
    }
    
    .section6_description {
        font-size: 12px;
        width: 100%;
        margin-top: 5px;
    }

    .instagram_container span {
        font-size: 14px;
    }

    .instagram_text2 {
        margin-top: 30px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .image_grid img {
        width: 150px;
        height: auto;
    }
}

/* Fold View */
@media (max-width: 280px) {
    .video_banner_detail_container {
        margin-top: -70px;
        padding-left: 30px;
        position: relative;
    }
    
    .title_video_banner {
        color: #FFF;
        font-size: 10px;
        margin-bottom: -10px;
    }
    
    .short_desc_video_banner {
        color: #FFF;
        font-size: 8px;
        margin-top: 0;
    }
    
    .app_link_buttons {
        margin-top: 5px;
    }
    
    .app_link_button {
        margin-right: 5px;
    }
    
    .app_link_image {
        width: 30px;
    }

    .section2_container {
        margin-top: 2%;
        margin-bottom: 5%;
        text-align: center;
    }

    .section2_title {
        font-style: italic;
        font-size: 10px;
    }
    
    .section2_description {
        font-size: 10px;
        font-style: italic;
        width: 85%;
        margin: 0 auto;
        margin-top: 0px;
        text-align: center;
    }

    .section3_image_container {
        width: 100%;
        padding-right: 0px;
        justify-content: center;
    }

    .section3_image {
        width: 50%;
        height: auto;
    }
    
    .section3_detail_container {
        text-align: center;
        margin-top: 10px;
        width: 80%;
        padding-inline: 20px;
    }
    
    .section3_title {
        font-size: 12px;
        font-weight: 500;
        width: 100%;
    }
    
    .section3_description {
        font-size: 8px;
        width: 100%;
        margin-top: 2px;
    }

    .section4_image {
        width: 60px;
        height: auto;
    }
    
    .section4_title {
        width: 80%;
        margin-top: 10px;
        font-size: 10px;
    } 

    .section5_image_container {
        width: 100%;
        padding-right: 0px;
        justify-content: center;
    }

    .section5_image {
        width: 50%;
        height: auto;
    }
    
    .section5_detail_container {
        text-align: center;
        margin-top: 10px;
        width: 80%;
        padding-inline: 20px;
    }
    
    .section5_title {
        font-size: 12px;
        font-weight: 500;
        width: 100%;
    }
    
    .section5_description {
        font-size: 8px;
        width: 100%;
        margin-top: 2px;
    }

    .section6_image_container {
        width: 100%;
        padding-right: 0px;
        justify-content: center;
    }

    .section6_image {
        width: 50%;
        height: auto;
    }
    
    .section6_detail_container {
        text-align: center;
        margin-top: 10px;
        width: 80%;
        padding-inline: 20px;
    }
    
    .section6_title {
        font-size: 12px;
        font-weight: 500;
        width: 100%;
    }
    
    .section6_description {
        font-size: 8px;
        width: 100%;
        margin-top: 2px;
    }

    .instagram_container span {
        font-size: 12px;
    }

    .instagram_text2 {
        margin-top: 25px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}



  


