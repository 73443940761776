.product_banner_container {
  position: relative;
}

.banner_content {
  position: absolute;
  left: 122px;
  bottom: 50%;
  transform: translateY(50%);
}

.banner_title {
  font-size: 44px;
  font-weight: 500;
}

.banner_description {
  font-size: 26px;
  width: 55%;
  margin-bottom: 0;
}

.product_banner {
  width: 100%;
  height: auto;
}

.product_list_container {
    display: grid;
    padding-inline: 20%;
    padding-block: 5%;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }
  
  .product_item {
    padding: 20px;
    border-radius: 8px;
  }
  
  .product_item h2 {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 500;
  }
  
  .product_item p {
    font-size: 14px;
    margin-bottom: 0;
  }

  /* Tablet View */
  @media (max-width: 1200px) {
    .banner_content {
      margin-right: 20px;
      margin-left: -50px;
    }    
    .banner_title {
      font-size: 32px !important;
    }
    .banner_description {
      font-size: 24px !important;
      width: 45%;
    }
    .product_list_container {
      display: grid;
      padding-inline: 10%;
      padding-block: 5%;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
    }
  }

  /* Landscape Tablet View */
  @media (max-width: 768px) {
    .banner_content {
      margin-right: 20px;
      margin-left: -70px;
    }    
    .banner_title {
      font-size: 22px !important;
    }
    .banner_description {
      font-size: 18px !important;
      width: 50%;
    }
    .product_list_container {
      display: grid;
      padding-inline: 5%;
      padding-block: 5%;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 5px;
    }
    .product_item h2 {
      font-size: 14px;
    }
  }

  /* Mobile View */
  @media (max-width: 576px) {
    .banner_content {
      margin-right: 60px;
      margin-left: -70px;
    }    
    .banner_title {
      font-size: 18px !important;
    }
    .banner_description {
      font-size: 14px !important;
      width: 40%;
    }
    .product_list_container {
      display: grid;
      padding-inline: 5%;
      padding-block: 5%;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 5px;
    }
    .product_item h2 {
      font-size: 14px;
    }
  }

  /* Landscape Mobile View */
  @media (max-width: 480px) {
    .banner_content {
      margin-right: 60px;
      margin-left: -70px;
    }    
    .banner_title {
      font-size: 12px !important;
    }
    .banner_description {
      font-size: 10px !important;
      width: 40%;
    }
    .product_list_container {
      display: grid;
      padding-inline: 2%;
      padding-block: 5%;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0px;
    }
    .product_item h2 {
      font-size: 12px;
    }
  }
  
