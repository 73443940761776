  .colorchart_banner_container {
    position: relative;
  }
  
  .banner_content {
    position: absolute;
    left: 122px;
    bottom: 50%;
    transform: translateY(50%);
  }
  
  .banner_title {
    font-size: 44px;
    font-weight: 500;
    color: white;
  }
  
  .banner_description {
    font-size: 26px;
    width: 100%;
    margin-bottom: 0;
    color: white;
  }
  
  .colorchart_banner {
    width: 100%;
    height: auto;
  }

  .colorchart_list_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    padding: 0;
  }

  .colorchart_item {
    width: 100%;
    margin: 20px 0;
  }

  .colorchart_list_title {
    text-align: center;
  }

  .colorchart_list_desc {
    text-align: center;
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    margin-inline: 37%;
    margin-bottom: 50px;
  }

  .colorchart_list_banner_container {
    background-color: rgb(239, 235, 234);
  }

  .colorchart_list_banner {
    width: 70%;
    display: block;
    margin: 0 auto;
    padding-block: 30px;
    
  }


  /* Landscape Tablet View */
  @media (max-width: 768px) {
    .colorchart_list_title {
      text-align: center;

    }
  
    .colorchart_list_desc {
      font-size: 16px;
      margin-inline: 20%;
    }
  }
  
  /* Landscape Mobile View */
  @media (max-width: 480px) {
    .colorchart_list_title {
      text-align: center;
      font-size: 12px !important;
    }
  
    .colorchart_list_desc {
      text-align: center;
      font-style: italic;
      font-weight: 500;
      font-size: 11px;
      margin-inline: 10%;
      margin-bottom: 50px;
    }
  }
