.product_detail_container {
    margin-left: 12%;
    margin-right: 20px;
    padding-block: 40px;
}

.breadcrumb {
    font-size: 16px;
    margin-bottom: 20px;
}

.product_detail_content {
    display: flex;
    flex-direction: row;
}

.product_mobile_view {
    display: flex;
    flex-direction: row;
}

.product_detail_images {
    display: flex;
    flex-direction: column;
    width: auto;
    height: 580px;
    margin-right: 30px;
}

.product_list {
    margin-bottom: 20px;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border-color 0.3s;
    height: 33%;
    object-fit: cover;
}

.product_list:hover {
    border-color: #007bff;
}

.product_detail_selected_image {
    width: auto;
    height: 625px;
    object-fit: cover;
}

.product_detail_text {
    margin-left: 50px;
}

.product_detail_title {
    font-size: 28px;
    font-weight: 600;
}

.product_detail_desc {
    width: 70%;
    font-weight: 500;
}

.product_detail_size {
    font-weight: 500;
}

.product_detail_colorchart {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.product_colorchart_title {
    font-size: 28px;
    font-weight: 500;
}

.product_colorchart_banner_container{ 
    background-color: rgb(239, 235, 234);
    margin-top: 20px;
    padding-block: 30px;
}

.product_colorchart_banner {
    width: 70%;
}

/* Tablet View */
@media (max-width: 1200px) {
    .product_detail_container {
        margin-left: 50px;
    }
    .breadcrumb {
        font-size: 14px;
        margin-bottom: 20px;
    }
    .product_detail_images {
        width: auto;
        height: 420px;
        margin-right: 20px;
    }
    .product_detail_selected_image {
        width: auto;
        height: 465px;
    }
    .product_detail_title {
        font-size: 20px;
        font-weight: 600;
    }
    .product_detail_desc {
        font-size: 16px;
    }
    .product_detail_size {
        font-size: 16px;
    }
    .product_colorchart_title {
        font-size: 24px;
        font-weight: 500;
    }
}

/* Landscape Tablet View */
@media (max-width: 768px) {
    .product_detail_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .product_detail_container {
        margin-inline: 50px;
    }
    .breadcrumb {
        font-size: 14px;
        margin-bottom: 20px;
    }
    .product_detail_images {
        width: auto;
        height: 420px;
        margin-right: 20px;
    }
    .product_detail_selected_image {
        width: auto;
        height: 465px;
    }
    .product_detail_title {
        font-size: 20px;
        font-weight: 600;
    }
    .product_detail_desc {
        width: auto;
        font-size: 16px;
    }
    .product_detail_size {
        font-size: 16px;
    }
    .product_colorchart_title {
        font-size: 24px;
        font-weight: 500;
    }
    .product_detail_text {
        margin-left: 0px;
        margin-top: 20px;
    }
}

/* Mobile View */
@media (max-width: 576px) {
    .product_detail_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .product_detail_container {
        margin-inline: 20px;
    }
    .breadcrumb {
        font-size: 12px;
        margin-bottom: 20px;
    }
    .product_detail_images {
        width: auto;
        height: 320px;
        margin-right: 20px;
    }
    .product_detail_selected_image {
        width: auto;
        height: 365px;
    }
    .product_detail_title {
        font-size: 16px;
        font-weight: 600;
    }
    .product_detail_desc {
        font-size: 12px;
    }
    .product_detail_size {
        font-size: 12px;
    }
    .product_colorchart_title {
        font-size: 16px;
        font-weight: 500;
    }
    .product_detail_text {
        margin-left: 0px;
        margin-top: 20px;
    }
}

/* Landscape Mobile View */
@media (max-width: 480px) {
    .product_detail_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .product_detail_container {
        margin-inline: 20px;
    }
    .breadcrumb {
        font-size: 10px;
        margin-bottom: 20px;
    }
    .product_detail_images {
        width: auto;
        height: 170px;
        margin-right: 20px;
    }
    .product_detail_selected_image {
        width: auto;
        height: 215px;
    }
    .product_detail_title {
        font-size: 14px;
        font-weight: 600;
    }
    .product_detail_desc {
        font-size: 10px;
    }
    .product_detail_size {
        font-size: 10px;
    }
    .product_colorchart_title {
        font-size: 14px;
        font-weight: 500;
    }
    .product_detail_text {
        margin-left: 0px;
        margin-top: 20px;
    }
}